<template>
  <div class="m-4">
    <form action="">
      <FormInput
        class="col-2"
        hint="asd"
        :required="true"
        :success="true"
      />
      <button>submit</button>
    </form>
    <!-- <hr>
    <FormInput />
    <hr>
    <FormInput />
    <hr>
    <FormInput />
    <hr>
    <FormInput />
    <hr>
    <FormInput />
     -->
    <IconLoader />
  </div>
</template>

<script>
import FormInput from './FormInput.vue'
import IconLoader from '@/components/IconLoader'

export default {
  name: 'DevForm',
  components: {
    FormInput,
    IconLoader,
  },
}
</script>

<style>

</style>
