<template>
  <svg class="remix">
    <use xlink:href="/images/r-icons/Buildings/bank-fill.svg" />
  </svg>
</template>

<script>
export default {
  name: 'IconLoader',
}
</script>

<style>

</style>
